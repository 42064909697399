
import { defineComponent } from 'vue'
import SiteForm from '@/components/SiteForm.vue'
import { ApSite } from '@/types'

export default defineComponent({
	name: 'SiteEdit',
	components: {
		SiteForm,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
	},
	data () {
		return {
			estimate: this.$store.getters.getSite(this.id),
		}
	},
	methods: {
		save (siteData: ApSite): void {
			this.$store.dispatch('upsertSite', siteData)
			this.$router.push({ name: 'site', params: { id: siteData.id } })
		},
		cancel (siteId: string): void {
			this.$router.push({ name: 'site', params: { id: siteId } })
		},
	},
})
