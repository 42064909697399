
import { defineComponent } from 'vue'
import { ApSite } from '@/types'

export default defineComponent({
	name: 'SiteList',
	props: {
		sites: {
			type: Array,
			required: true,
		},
	},
	emits: ['site-selected'],
	methods: {
		rowClick (row: ApSite): void {
			this.$emit('site-selected', row.id)
		},
	},
})
