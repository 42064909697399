<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'SiteForm',
	props: {
		site: {
			type: Object,
			required: true,
		},
		isNew: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['submit', 'cancel'],
	data () {
		return {
			localSite: JSON.parse(JSON.stringify(this.site)),
		}
	},
	methods: {
		onSubmit (): void {
			this.$emit('submit', this.localSite)
		},
		onCancel (): void {
			this.$emit('cancel', this.localSite.id)
		},
	},
})
</script>

<template>
	<el-form ref="form" :model="site" label-width="200px">
		<el-form-item label="Nom">
			<el-input v-model="localSite.name"></el-input>
		</el-form-item>
		<el-form-item>
			<el-button type="primary" @click="onSubmit">Enregistrer</el-button>
			<el-button @click="onCancel">Annuler</el-button>
		</el-form-item>
	</el-form>
</template>
