
import { defineComponent } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { ApSite } from '@/types'
import SiteForm from '@/components/SiteForm.vue'

export default defineComponent({
	name: 'SiteAdd',
	components: {
		SiteForm,
	},
	data () {
		return {
			site: {
				id: uuidv4(),
				name: '',
			},
		}
	},
	methods: {
		save (siteData: ApSite): void {
			this.$store.dispatch('upsertSite', siteData)
			this.$router.push({ name: 'site', params: { id: siteData.id } })
		},
		cancel (siteId: string): void {
			this.$router.push({ name: 'site', params: { id: siteId } })
		},
	},
})
