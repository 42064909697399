
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'SiteForm',
	props: {
		site: {
			type: Object,
			required: true,
		},
		isNew: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['submit', 'cancel'],
	data () {
		return {
			localSite: JSON.parse(JSON.stringify(this.site)),
		}
	},
	methods: {
		onSubmit (): void {
			this.$emit('submit', this.localSite)
		},
		onCancel (): void {
			this.$emit('cancel', this.localSite.id)
		},
	},
})
