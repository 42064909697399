<script lang="ts">
import { defineComponent } from 'vue'
import { ApSite } from '@/types'

export default defineComponent({
	name: 'SiteList',
	props: {
		sites: {
			type: Array,
			required: true,
		},
	},
	emits: ['site-selected'],
	methods: {
		rowClick (row: ApSite): void {
			this.$emit('site-selected', row.id)
		},
	},
})
</script>

<template>
	<el-table :data="sites" style="width: 100%" @row-click="rowClick">
		<el-table-column prop="name" label="Name" width="300"></el-table-column>
	</el-table>
</template>
