<script lang="ts">
import { defineComponent } from 'vue'
import SiteCard from '@/components/SiteCard.vue'
import { ApSite } from '@/types'

export default defineComponent({
	name: 'ApSite',
	components: {
		SiteCard,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
	},
	computed: {
		site (): ApSite {
			return this.$store.getters.getSite(this.id)
		},
	},
})
</script>

<template>
	<site-card :site="site"> </site-card>
</template>

<style></style>
