<script lang="ts">
import { defineComponent } from 'vue'
import SiteList from '@/components/SiteList.vue'
import { ApSite } from '@/types'

export default defineComponent({
	name: 'ApSites',
	components: {
		SiteList,
	},
	computed: {
		sites (): ApSite[] {
			return this.$store.state.sites
		},
	},
	methods: {
		selectSite (id: string): void {
			this.$router.push({ name: 'site', params: { id } })
		},
	},
})
</script>

<template>
	<site-list :sites="sites" @site-selected="selectSite"></site-list>
	<el-button type="primary" @click="$router.push({ name: 'siteAdd' })">Ajouter</el-button>
</template>
